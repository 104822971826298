<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Rule_Name')">
                <el-input
                  placeholder="请输入规则名称"
                  v-model="formInline.ruleName"
                  :disabled="false"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Rule_label')">
                <el-input
                  placeholder="请输入规则标签"
                  v-model="formInline.ruleTag"
                  :disabled="false"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Route_type')">
                <el-select v-model.trim="formInline.ruleType" filterable size="12">
                  <el-option label="请选择" value></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in urlTypeList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="primary" icon="el-icon-plus" @click="addRuletype">{{ $t('button.addto') }}</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a">详情</el-dropdown-item>
                  <el-dropdown-item command="b">编辑</el-dropdown-item>
                  <el-dropdown-item command="c">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setIndex } from "@/common/js/public.js";
export default {
  name: "datatule",
  data() {
    return {
      loading: "",
      total: 0,
      tableData: [],
      page: 1,
      size: 15,
      urlTypeList: [],
      formInline: {
        ruleType: "",
        ruleName: "",
        ruleTag: "",
      },
      tableCols: [
        {
          prop: "ruleName",
          label: this.$t("list.Rule_Name"),
          width: "150",
        },
        {
          prop: "ruleTag",
          label: "规则标签",
          width: "100",
        },
        {
          prop: "ruleTypeName",
          label: "路由类型",
          width: "150",
        },
        {
          prop: "ruleScopName",
          label: "路由对象",
          width: "150",
        },
        {
          prop: "url",
          label: "请求地址",
          width: "150",
        },
        {
          prop: "callbackUrl",
          label: "回调地址",
          width: "150",
        },
        {
          prop: "tokenUrl",
          label: "tonkenUrl",
          width: "150",
        },
        {
          prop: "processor",
          label: "处理器",
          width: "150",
        },
        {
          prop: "accessKey",
          label: "accesskey",
          width: "150",
        },
        {
          prop: "secret",
          label: "secret",
          width: "150",
        },
        {
          prop: "remark",
          label: "备注",
          width: "150",
        },
        {
          prop: "ruleState",
          label: this.$t("list.state"),
          width: "150",
          formatter: (row) => {
            if (row.ruleState == 1) {
              return "禁用";
            } else {
              return "启用";
            }
          },
        },
      ],
    };
  },
  methods: {
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取路由类型列表
    deteleRoute(id) {
      this.$axios.get("/acb/2.0/edrsRule/delete/" + id).then((res) => {
        if (res.state == 0) {
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    detail(businessParameterConfigurationId) {
      this.$axios
        .get("/acb/2.0/businessParameterConfiguration/getById", {
          data: {
            businessParameterConfigurationId: businessParameterConfigurationId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.addformInline = res.value;
            this.businessParameterConfigurationId = res.value.businessParameterConfigurationId;
            if (res.value) {
              let item = res.value.itemCode;
              if (item == 7 && res.value.parameterValue != 1) {
                let arr = res.value.parameterValue.split(",");
                for (var i = 0; i < arr.length; i++) {
                  if (arr[i] == 0) {
                    this.addformInline.parameterValue = "0";
                  } else if (arr[i] == 0) {
                    this.exitType = "0";
                  } else if (arr[i] == 1) {
                    this.exitType = "1";
                  }
                }
              }
              if (item !== 1 && item !== 6 && item !== 7) {
                this.rule.operationId[0].required = false;
                this.rule.areaId[0].required = false;
              } else {
                this.rule.operationId[0].required = true;
                this.rule.areaId[0].required = true;
              }
            }
            // console.log(this.businessParameterConfigurationId, 'businessParameterConfigurationId')
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        data.showTag = 1;
        this.$router.push({
          path: "/ruleEdit",
          query: {
            data: data,
          },
        });
      } else if (cmd == "b") {
        this.$router.push({
          path: "/ruleEdit",
          query: {
            data: data,
          },
        });
      } else if (cmd == "c") {
        this.$confirm("此操作将删除本条配置, 是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$axios.post(`/acb/2.0/edrsRule/delete/${data.edrsRuleId}`).then((res) => {
              if (res.state == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    addRuletype() {
      this.$router.push({
        path: "/ruleEdit",
      });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    resetForm() {
      this.formInline.ruleType = "";
      this.formInline.ruleTag = "";
      this.formInline.ruleName = "";
    },
    searchData() {
      this.$axios
        .get("/acb/2.0/edrsRule/list", {
          data: {
            page: this.pageNum,
            pageSize: this.pageSize,
            ruleName: this.formInline.ruleName,
            ruleType: this.formInline.ruleType,
            ruleTag: this.formInline.ruleTag,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 获取路由类型
    getRuletype(val) {
      let url = "/acb/2.0/edrsRule/droplist";
      this.$axios.get(url, { data: {} }).then((res) => {
        this.urlTypeList = res.value;
      });
    },
  },
  components: {},
  created() {},
  mounted() {
    this.pageNum = 1;
    this.pageSize = 15;
    this.getRuletype();
    this.searchData();
  },
  watch: {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.conborder {
  border-top: 1px solid #C0CCDA;
  overflow: hidden;
  padding: 10px;
}

.content {
  overflow: hidden;
  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.color_1 {
  color: #3d94dd;
}

.color_2 {
  color: #D9001B;
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.avatar-uploader-icon {
  cursor: pointer;
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}

.avatar {
  width: 100%;
  display: block;
}

>>>.el-dialog .el-form-item__content {
  line-height: 20px;
}

.el-dialog div {
  font-size: 14px;
  color: #B3B3B3;
}
.left10
  margin-left: 10px;
</style>
